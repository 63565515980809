import { Badge, Box, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { useCountUnactionedComments } from '../../../api/comments';
import { useGetOutputs } from '../../../api/outputs';
import { GlobalAppContext } from '../../../store/AppStore';
import { AuthContext } from '../../../store/AuthStore';
import useStyles from './styles';
import { WorkspaceCodeAndName } from './WorkspaceCodeAndName';
import { WorkspaceComments } from './WorkspaceComments';
import { WorkspaceSummary } from './WorkspaceSummary';

function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const WorkspaceTabs = () => {
  let query = useQueryParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { authUser } = useContext(AuthContext);
  const {
    slideBar: [, setSlideLeftBarIsOpen],
    forCodeSelected: [forCode, setForCode]
  } = useContext(GlobalAppContext);

  useEffect(() => {
    if (query.get('slideLeftBarIsOpen') === 'true') {
      setSlideLeftBarIsOpen(true);
    }
    if (query.get('tab') !== null) {
      setTabNum(parseInt(query.get('tab')));
    }
    if (query.get('FoRCode') !== null && query.get('FoRCodeName') !== null) {
      if (authUser.userInformation.allAssignedForCodes.includes(query.get('FoRCode'))) {
        setForCode({ FoRCode: query.get('FoRCode'), FoRCodeName: query.get('FoRCodeName') });
        queryClient.invalidateQueries('outputs');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('tab'), query.get('forCode')]);

  const [tabNum, setTabNum] = useState(0);
  const indexes = {
    first: 0,
    second: 1,
    third: 2,
    fourth: 3
  };
  const classes = useStyles();

  const outputsFetch = useGetOutputs(
    0,
    1,
    forCode.FoRCode,
    { field: 'pubPk', sort: 'desc' },
    [],
    '',
    [],
    true
  );

  const commentsUnactionedCount = useCountUnactionedComments(forCode.FoRCode);

  const handleChangeTab = (event, newValue) => {
    history.push(
      `/workspace?tab=${newValue}&FoRCode=${forCode.FoRCode}&FoRCodeName=${forCode.FoRCodeName}`
    );
  };

  return (
    <div data-testid="workspace-tabs" className={classes.allTabs}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabNum}
          onChange={handleChangeTab}
          aria-label="tabs menu for workspace"
          className={classes.tabGroup}
          selectionFollowsFocus>
          <Tab
            label="Outputs"
            id="tab-code-and-name"
            data-testid="tab-code-and-name"
            aria-controls="tabpanel-code-and-name"
            className={clsx(classes.tabHeader, tabNum === indexes.first && classes.activeTab)}
            disableRipple
          />

          <Tab
            label="For Action"
            icon={
              outputsFetch.isSuccess &&
              outputsFetch.data?.totalResults > 0 && (
                <Badge
                  badgeContent={outputsFetch.data.totalResults}
                  data-testid="tab-for-action-badge"
                  className={classes.badgeIcon}
                />
              )
            }
            iconPosition="end"
            id="tab-for-action"
            data-testid="tab-for-action"
            aria-controls="tabpanel-for-action"
            className={clsx(classes.tabHeader, tabNum === indexes.second && classes.activeTab)}
            disableRipple
          />
          <Tab
            label="Comments"
            icon={
              commentsUnactionedCount.isSuccess &&
              commentsUnactionedCount.data > 0 && (
                <Badge
                  badgeContent={commentsUnactionedCount.data}
                  data-testid="tab-for-action-badge"
                  className={classes.badgeIcon}
                />
              )
            }
            iconPosition="end"
            id="tab-comments"
            data-testid="tab-comments"
            aria-controls="tabpanel-comments"
            className={clsx(classes.tabHeader, tabNum === indexes.third && classes.activeTab)}
            disableRipple
          />
          <Tab
            label="Summary Data"
            id="tab-summary-data"
            data-testid="tab-summary-data"
            aria-controls="tabpanel-summary-data"
            className={clsx(classes.tabHeader, tabNum === indexes.fourth && classes.activeTab)}
            disableRipple
          />
        </Tabs>
      </Box>

      {tabNum === indexes.first && (
        <div
          role="tabpanel"
          hidden={tabNum !== indexes.first}
          id="tabpanel-code-and-name"
          aria-labelledby="tab-code-and-name">
          <WorkspaceCodeAndName />
        </div>
      )}
      {tabNum === indexes.second && (
        <div
          role="tabpanel"
          hidden={tabNum !== indexes.second}
          id="tabpanel-for-action"
          aria-labelledby="tab-for-action">
          <WorkspaceCodeAndName forAction={true} />
        </div>
      )}
      {tabNum === indexes.third && (
        <div
          role="tabpanel"
          hidden={tabNum !== indexes.third}
          id="tabpanel-comments"
          aria-labelledby="tab-comments">
          <WorkspaceComments />
        </div>
      )}
      {tabNum === indexes.fourth && (
        <div
          role="tabpanel"
          hidden={tabNum !== indexes.fourth}
          id="tabpanel-summary-data"
          aria-labelledby="tab-summary-data">
          <WorkspaceSummary />
        </div>
      )}
    </div>
  );
};
